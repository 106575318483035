import React from "react";
import './Services.css';
import Card from "../Card/Card";
// import HeartEmoji from '../../img/heartemoji.png';
import Seo from '../../img/SeoWeb.gif'
import androidApp from '../../img/androidLogo.gif'
import windowsApp from '../../img/windows.gif'
// import Glasses from '../../img/glasses.png';
// import Humble from '../../img/humble.png';
import Resume from './resume.pdf'
import {themeContext} from '../../Context';
import { useContext } from "react";
import {motion} from 'framer-motion';

const Services = () =>{
    const transition = {duration : 3, type: 'spring'}
    const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
    return(
        <div className="services" id='Services'>

            {/* left side */}
            <div className="awesome">
                <span style={{color: darkMode? 'white':''}}>Services</span>
                <span>Provided</span>
                <spane> All sorts of Mobile, Web and  
                    <br/>
                    Windows based application can be delivered and supported
                    </spane>
                <a href={Resume} download>
                    <button className="button s-button">Download CV</button>
                </a>
                <div className="blur s-blur" style={{background: "#ABF1FF94"}}></div>
            </div>

            {/* right side */}
            <div className="cards">
                {/* card 1 : WEB development */}
                <motion.div 
                initial={{left:'5rem'}}
                whileInView={{left:'14rem'}}
                transition={transition}
                style={{left:'14rem'}}>
                    <Card 
                    emoji = {Seo}
                    heading = {'Web & SEO'}
                    detail = {"Development & Hosting, SEO & Digital Marketing"}></Card>
                </motion.div>

                {/* Card 2 Android Development */}
                <motion.div 
                initial={{top:'5rem',left:'-1rem'}}
                whileInView={{top:'10rem',left:'-4rem'}}
                transition={transition}
                style={{top:'10rem',left:'-4rem'}}>
                    <Card 
                    emoji = {androidApp}
                    heading = {'Mobile Platform'}
                    detail = {"Android Studio, Java, SQLite, Oracle"}></Card>
                </motion.div>

                {/* Card 2 Windows Development */}
                <motion.div 
                initial={{top:'10rem',left:'9rem'}}
                whileInView={{top:'18rem',left:'12rem'}}
                transition={transition}
                style={{top:'18rem',left:'12rem'}}>
                    <Card 
                    emoji = {windowsApp}
                    heading = {'Windows Platform'}
                    detail = {"Visula Studio, C#, WPF, SQLite, Oracle"}></Card>
                </motion.div>
                <div className="blur s-blur2" style={{background: "var(--purple)" }}></div>
            </div>

        </div>
    )
}

export default Services