import React, { useRef } from 'react';
import './Contact.css';
import emailjs from '@emailjs/browser';
import { useState } from 'react';
import {themeContext} from '../../Context';
import { useContext } from "react";

const Contact = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  const form = useRef();
  const[done, setDone] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs.sendForm('service_d079wvl', 'template_8207lfp', form.current, '7siDvUlXTY37j7ZrY')
      .then((result) => {
          console.log(result.text);
          setDone(true);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="contact-form" id='Contact'>
      <div className="w-left">
        <div className="awesome">
          <span style={{color: darkMode? 'white':''}}>Get in touch</span>
          <span>Contact me.!</span>
          <div className="blur s-blur"
                style={{background: "#ABF1FF94"}}></div>
        </div>
      </div>

      <div className='c-right'>
        <form ref={form} onSubmit={sendEmail} >
          <input type="text" name="user_name" className="user" placeholder="Name"></input>
          <input type="email" name="user_email" className="user" placeholder="Email"></input>
          <textarea type="message" name="message" className="user" placeholder="Message"/>
          <input type="submit" value="Send" className="button"/>
          <span style={{color: darkMode? 'white':''}}>{done && "Thanks for contacting me..!"}</span>
          <div className="blur c-blur"
               style={{background: "var(--purple)"}}></div>
        </form>
      </div>
    </div>

   
  )
}

export default Contact