import React from 'react';
import './Portfolio.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import Sidebar from '../../img/sidebar.png';
import Ecommerce from '../../img/ecommerce.png';
import HOC from '../../img/hoc.png';
import MesicApp from '../../img/musicapp.png';
import 'swiper/css';
import {themeContext} from '../../Context';
import { useContext } from "react";

const Portfolio = () => {
    const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id='Portfolio'>
        {/* heading */}
        <span style={{color: darkMode? 'white':''}}>Recent Projects</span>
        <span>Portfolio</span>

        {/* swiping projects */}
        <Swiper
        spaceBetween={30}
        slidesPerView={2.5}
        grabCursor={true}
        className='portfolio-slider'>
            <SwiperSlide>
                <img src={Sidebar} alt=""></img>
            </SwiperSlide>
            <SwiperSlide>
                <img src={Ecommerce} alt=""></img>
            </SwiperSlide>
            <SwiperSlide>
                <img src={MesicApp} alt=""></img>
            </SwiperSlide>
            <SwiperSlide>
                <img src={HOC} alt=""></img>
            </SwiperSlide>
        </Swiper>
    </div>
  )
}

export default Portfolio