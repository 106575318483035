import React from 'react';
import './Works.css';
import ami from '../../img/americanmegatrends.png';
import scio from '../../img/sciohealthAnalytics.jpg';
import emis from '../../img/emishealth.png';
import {themeContext} from '../../Context';
import { useContext } from "react";
import {motion} from 'framer-motion';

const Works = () => {
    const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
   <div className="works">
    <div className="awesome">
                <span style={{color: darkMode? 'white':''}}>Professionally Worked for</span>
                <span>Organisations</span>
                <spane> #1 Scio Health Analytics  
                    <br/>
                    Span : May/2018 - Oct/2020
                    <br/>
                    Projects:   
                    <br/>
                    1. Activity Tracker: Windows Based Application for monitoring 
                    <br/>
                    Regular activities of the peers
                    <br/>
                    2. Performance Management : Windows Based Application for monitoring 
                    <br/>
                    Regular activities of the peers
                </spane>
                    <button className="button s-button">Hire Me</button>
                <div className="blur s-blur" style={{background: "#ABF1FF94"}}></div>
            </div>

            {/* right side circle */}
            <div className='w-right'>
                <motion.div 
                initial={{rotate:-45}}
                whileInView={{rotate:0}}
                viewport={{margin:'-40px'}}
                transition={{duration:3.5,type:'spring'}}
                className="w-mainCircle">
                    <div className="w-secCircle">
                        <img src={scio} alt="scio logo"></img>
                    </div>
                    <div className="w-secCircle">
                        <img src={emis} alt="scio logo"></img>
                    </div>
                    <div className="w-secCircle">
                        <img src={ami} alt="scio logo"></img>
                    </div>
                </motion.div>
                {/* background circles */}
                <div className="w-backCircle blueCircle"></div>
                <div className="w-backCircle yellowCircle"></div>
            </div>
   </div>
  )
}

export default Works