import React from "react";
import './Intro.css';
import FloatingDiv from "../FloatingDiv/FloatingDiv";
import Github from '../../img/github.png';
import LinkedIn from '../../img/linkedin.png';
import Instagram from '../../img/instagram.png';
import Vector1 from '../../img/Vector1.png';
import Vector2 from '../../img/Vector2.png';
import boy from '../../img/boy.png';
import thumbup from '../../img/thumbup.png';
import AndroidGif from '../../img/android.gif';
import WindowsGif from '../../img/windows.gif';
import glassesimoji from '../../img/glassesimoji.png';
import {themeContext} from '../../Context';
import { useContext } from "react";
import { motion } from 'framer-motion';

const Intro = () =>{

    const transition = {duration : 2, type: 'spring'}

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    return(
        <div className="intro">
            <div className="i-left">
                <div className="i-name">
                    <span style={{color: darkMode? 'white':''}}>Hi.! I'Am</span>
                    <span>Shoban Kumar</span>
                    <span>Full Stack Developer with high
                    level of experience in windows and android 
                    application design & development, producing
                    the Quality work</span>
                </div>
                <button className="button i-button">Hire me</button>
                <div className="i-icons">
                    <a href="https://github.com/Shoban07">
                      <img src={Github} alt="Github Link" />
                    </a>
                    <a href="https://www.linkedin.com/in/shoban-kumar-b77508141/">
                    <img src={LinkedIn} alt="Linkedin Link" />
                    </a>
                    <a href="https://www.instagram.com/shoban.kumar.7/">
                    <img src={Instagram} alt="Instagram Link" />
                    </a>
                </div>
            </div>
            <div className="i-right">
                <img src={Vector1} alt="Vector1"></img>
                <img src={Vector2} alt="Vector2"></img>
                <img src={boy} alt="Vector2"></img>
                {/* <img src={glassesimoji} alt="Vector2"/> */}
                <motion.img
                initial={{left: '-36%'}}
                whileInView={{left: '-24%'}} 
                transition={transition}
                src={glassesimoji} 
                alt="Vector2"
                />
                <motion.div 
                initial={{top:'-4%',left:'74%'}}
                whileInView={{left:'68%'}}
                transition={transition}
                style={{top:'-4%',left:'68%'}}
                className='floating-div'>
                    <FloatingDiv image={AndroidGif} txt1='Android' txt2='Developer'/>
                </motion.div>

                <motion.div 
                initial={{bottom:'18%',right:'50%'}}
                whileInView={{right:'60%'}}
                transition={transition}
                style={{bottom:'18%',right:'60%'}}
                className='floating-div'>
                    <FloatingDiv image={thumbup} txt1='Customer Impact' txt2='Award'/>
                </motion.div>

                <motion.div 
                initial={{bottom:'30%',left:'50%'}}
                whileInView={{left:'72%'}}
                transition={transition}
                style={{bottom:'30%',left:'72%'}}
                className='floating-div'>
                    <FloatingDiv image={WindowsGif} txt1='.Net' txt2='Developer'/>
                </motion.div>
                {/* blur divs below*/}
                <div className='blur' style={{ background: "rgb(238 210 255)" }}></div>
                <div className='blur' 
                style={{ 
                    background: '#C1F5FF',
                    top:'17rem',
                    width:'21rem',
                    height:'-9rem'
                    }}></div>
            </div>
        </div>
    )
}


export default Intro;