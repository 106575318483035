import React from 'react'
import './Footer.css';
import Wave from '../../img/wave.png';
import Insta from '@iconscout/react-unicons/icons/uil-instagram';
import LinkedIn from '@iconscout/react-unicons/icons/uil-linkedin';
import Github from '@iconscout/react-unicons/icons/uil-github';
import Mail from '@iconscout/react-unicons/icons/uil-fast-mail';

const Footer = () => {
  return (
   <div className="footer">
    <img src={Wave} alt="footer background" style={{width:'100%'}}></img>
    <div className="f-content">
        <span><Mail color='white' size='2rem'/> rsvshobankumar.ram@gmail.com</span>
        <div className="f-icons">
            <a href="https://github.com/Shoban07">
                <Github color='white' size='3rem'/>
            </a>
            <a href="https://www.linkedin.com/in/shoban-kumar-b77508141/">
                <LinkedIn color='white' size='3rem'/>
            </a>
            <a href="https://www.instagram.com/shoban.kumar.7/">
                <Insta color='white' size='3rem'/>
            </a>
        </div>
    </div>
   </div>
  )
}

export default Footer