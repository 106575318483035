import React from 'react'
import  './Experience.css'
import {motion} from 'framer-motion';

const Experience = () => {
  const transition = {duration : 3, type: 'spring'}

  return (
    <div className="experience" id='Experience'>
        <div className="achievement">
            <motion.div 
            initial ={{left:'100%'}}
            whileInView={{left:'0%'}}
            transition={transition}
            className="circle">4+</motion.div>
            <span>overall years </span>
            <span>Experience</span>
        </div>
        <div className="achievement">
            <motion.div 
            initial={{bottom:'100%'}}
            whileInView={{top:'0%'}}
            transition={transition}
            className="circle">6+</motion.div>
            <span>completed </span>
            <span>Projects</span>
        </div>
        <div className="achievement">
            <motion.div 
            initial ={{right:'100%'}}
            whileInView={{right:'0%'}}
            transition={transition}
            className="circle">2+</motion.div>
            <span>worked </span>
            <span>Companies</span>
        </div>
    </div>
  )
}

export default Experience